export default [
    {
        path: "/coupon_add",
        name: "coupon_add",
        meta: { title: "优惠券" },
        component: () => import("@/views/tool/coupon/coupon_add.vue"),
    },
    {
        path: "/coupon_detail",
        name: "coupon_detail",
        meta: { title: "优惠券" },
        component: () => import("@/views/tool/coupon/coupon_detail.vue"),
    },
    {
        path: "/invitation_coupon_list",
        name: "invitation_coupon_list",
        meta: { title: "体验券" },
        component: () => import("@/views/tool/invitation_coupon_list.vue"),
    },
    {
        path: "/invitation_coupon_detail",
        name: "invitation_coupon_detail",
        meta: { title: "体验券" },
        component: () => import("@/views/tool/InvitationCoupon/invitation_coupon_detail.vue"),
    },
    {
        path: "/gicc_list",
        name: "gicc_list",
        meta: { title: "gicc评论审核" },
        component: () => import("@/views/tool/gicc_list/gicc_list.vue"),
    },
    {
        path: "/feedback_list",
        name: "feedback_list",
        meta: { title: "gicc评论审核" },
        component: () => import("@/views/tool/feedback_list/index.vue"),
    },
    {
        path: "/bonus_coupon_list",
        name: "bonus_coupon_list",
        meta: { title: "积分券" },
        component: () => import("@/views/tool/bonus_coupon_list/bonus_coupon_list.vue"),
    },
    {
        path: "/bonus_coupon_add",
        name: "bonus_coupon_add",
        meta: { title: "积分券" },
        component: () => import("@/views/tool/bonus_coupon_list/bonus_coupon_add.vue"),
    },
    {
        path: "/bonus_coupon_detail",
        name: "bonus_coupon_detail",
        meta: { title: "积分券" },
        component: () => import("@/views/tool/bonus_coupon_list/bonus_coupon_detail.vue"),
    },
    {
        path: "/form_list",
        name: "form_list",
        meta: { title: "表单" },
        component: () => import("@/views/tool/form_list/"),
    },
    {
        path: "/search_log_list",
        name: "search_log_list",
        meta: { title: "表单" },
        component: () => import("@/views/tool/search_log_list/"),
    },
    {
        path: "/chatgpt",
        name: "chatgpt",
        meta: { title: "AI问答" },
        component: () => import("@/views/chatgpt/"),
    },
    {
        path: "/email_task_list",
        name: "email_task_list",
        meta: { title: "邮件群发" },
        component: () => import("@/views/tool/email_task_list/index.vue"),
    },
    {
        path: "/new_email_task_list",
        name: "new_email_task_list_new",
        meta: { title: "邮件群发" },
        component: () => import("@/views/tool/email_task_list/new_email_task_list.vue"),
    },
    {
        path: "/sms_template_list",
        name: "sms_template_list",
        meta: { title: "短信管理" },
        component: () => import("@/views/tool/sms_template_list"),
    },
    {
        path: "/sms_template_list_add",
        name: "sms_template_list_add",
        meta: { title: "短信管理" },
        component: () => import("@/views/tool/sms_template_list/add.vue"),
    },
    {
        path: "/sms_user_list",
        name: "sms_user_list",
        meta: { title: "收件人列表" },
        component: () => import("@/views/tool/sms_user_list"),
    },
    {
        path: "/sms_user_list_management",
        name: "sms_user_list_management",
        meta: { title: "管理收件人" },
        component: () => import("@/views/tool/sms_user_list/management.vue"),
    },
    {
        path: "/sms_task_list",
        name: "sms_task_list",
        meta: { title: "短信发送任务" },
        component: () => import("@/views/tool/sms_task_list"),
    },
    {
        path: "/message_list",
        name: "message_list",
        meta: { title: "私信记录" },
        component: () => import("@/views/tool/message_list"),
    },
    {
        path: "/gicc_vote",
        name: "gicc_vote",
        meta: { title: "大众投票" },
        component: () => import("@/views/tool/gicc_vote/index.vue"),
    }
];
